body{
  font-family: 'Teko', sans-serif !important;
}



.hdr_section{
   background: url('../../img/vrai/data-slider-bg.png');   
   background-size: cover; 
   background-position: center bottom;  
   width: 100%; height: 100%; 
   display:flex;	
   align-items:center; 
   flex-direction:column; 
   padding-top:180px;
}

.inner_hdr_section{
  background: url('../../img/vrai/aboutus-bg.png');   
  background-size: cover; 
  background-position: center bottom;  
  width: 100%;   
  display:flex;	
  align-items:center; 
  flex-direction:column; 
  padding:180px 0 100px;
}

h1.title{
  color:#fff;
  font-size: 95px;
}

.hdr_content{
  color: #fff;
  font-size: 28px;
  font-weight: 300;
  font-family: 'Barlow Condensed';
}



.booknow-btn{
  font-weight: 500;
  font-size: 26px;
  border: solid 2px rgb(255, 255, 255);
  font-family: Teko;
  color: /* rgb(13, 15, 57) */ rgb(255, 255, 255);
  line-height: 40px;
  padding-left: 35px;
  border-radius: 3px;
  padding-right: 35px;
  padding-top: 5px;
  margin-top: 20px;
}

.booknow-btn:hover{
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  text-decoration: none;
}

#Top_bar{
  top: 10px !important;
}

#Top_bar.is-sticky{
  top: 0px !important;
}

#Top_bar .menu > li > a {
  color: #ffffff !important;
}

#Top_bar.is-sticky .menu > li > a {
  color: #000 !important;
}

.waves-box { 
  z-index: 1;
  padding: 40px 36px;
  border-radius: 50%;
  background: #0000004d;
}



.mcb-wrap-inner h2{
  font-size: 54px;
}

.mcb-wrap-inner h6{
  font-size: 24px;
}

.mcb-wrap-inner p, footer p, footer{
  font-family: 'Barlow Condensed', sans-serif;
}

.mcb-wrap-inner p, footer p{
  line-height: 30px;
  font-size: 18px;
}

.footer-copy-center #Footer .footer_copy .copyright {
  font-size: 17px;
}

.menu-item a, a.action_button{
  font-family: 'Barlow Condensed', sans-serif;
}

a.action_button{
  font-size: 18px;
}

.is-sticky a.action_button{
  color: #000;
}

footer h6 {
  font-size: 24px;
}

.ftr_link a{
  color: #fff !important;
  line-height: 40px;
  margin-left: 30px;
  font-weight: 300;
  font-size: 20px;
}

.mcb-wrap{
  font-size: 30px;
}

.menuo-no-borders #Top_bar .menu > li > a span {
  border-width: 0!important;
  font-size: 18px;
}

hr.space{
  border-top: solid 1px rgba(255,255,255,0.4);
}

.callaction{
  text-align: center;
  font-size: 26px;
  margin-bottom: 60px !important;
}

.callaction p{
  line-height: 34px;
  font-size: 22px;
}

#Top_bar .menu > li.current-menu-item > a, #Top_bar .menu > li.current_page_item.menu-item > a{
  color: #a52282;
}

#Top_bar .menu > li.current-menu-item > a, #Top_bar .menu > li.menu-item > a:hover{
  color: #a52282;
}

.semititle{
  font-size: /* 36px */ 56px;
  color: #0d0f39;
}

.pricing_container{
  max-width: 1640px;
}


.image_frame.whitebg, .image_frame.graybg, .image_frame.purplebg, .image_frame.bluebg{
  margin-top: -160px;
}

.pricing_container .image_wrapper{
  text-align: center;
}


.whitebg img, .graybg img, .purplebg img, .bluebg img{
  height: 172px;
}

.freedollar{
  text-indent: -9999px;
    display: inline-block;
}

.image_frame.whitebg:before, .image_frame.graybg:before, .image_frame.purplebg:before, .image_frame.bluebg:before{
  content: '';
  padding: 0;
  width: 346px;
  height: 200px;
  position: absolute;
  top: -40px;
  left: auto;
  margin: 0;
  right: -20px;
}


.pricing-header .image_frame.whitebg:before, .pricing-header .image_frame.graybg:before, .pricing-header .image_frame.purplebg:before, .pricing-header .image_frame.bluebg:before{
  right: -38px;
}



.image_frame.whitebg:before{
  background: url(../../img/vrai/white-bg.png) no-repeat 0 0;
  right: -8px ;
    top: -50px ;
}

.image_frame.graybg:before{
  background: url(../../img/vrai/gray-bg.png) no-repeat 0 0;
}

.image_frame.purplebg:before{
  background: url(../../img/vrai/purple-bg.png) no-repeat 0 0; 
}

.image_frame.bluebg:before{
  background: url(../../img/vrai/blue-bg.png) no-repeat 0 0;
}


.tableoption ul{
  list-style: none;
  margin: 0;
}

.pricetabletitle{
  transform: scale(1);
  text-align: center;
}

.whitebg~.pricetabletitle p{
  color: #11214b;
}

.pricetabletitle p{
  color: #fff;
  height: 95px;
}

.pricetabletitle .semititle{
  margin-bottom: 20px;
}

.pricetabletitle small{
  opacity: .4;
}

.blueclr{
  color: #014dc1;
}

input[type="email"]{
  width:100% !important;
}

textarea.form-control.form-textarea{
  margin: 0 15px;
    width: 99%;
}

.successmsg{
  background-color: #38a850;
  border-radius: 10px;
  margin-top: 50px;
  padding: 10px;
  text-align: center;
}

.successmsg p {
  color: #fff;
  margin: 0;
  font-size: 24px;
  font-weight: 300;
}

.purpleclr{
  color: #ba00b9;
}

.table_format{
  padding: 20px 0px;
  border-radius: 10px;
  box-shadow: 0 0 40px #e8e8f4;
  margin-bottom: 20px;
}

.table_format ul li{  
  font-size: 20px;
  font-family: 'Barlow Condensed', sans-serif;
    color: #11214b;
    font-weight: 500;
    margin: 0;
    border-bottom: solid 1px #fff;
    border-top: solid 1px #eaeaf1;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table_format ul li:nth-child(2n+2){  
  background: #f8f8ff;
}

.table_wrap{
  margin-top: 300px;
}

.table_wrap .table_format ul li{  
  padding: 0 20px;
  justify-content: flex-start;
    text-align: left;
}

.modal.show .modal-dialog { 
  justify-content: center;
}



li.whitecheck{
  background: url(../../img/vrai/ligth-gray-check-icon.png) no-repeat center center !important;
}
li.graycheck{
  background: url(../../img/vrai/gray-check-icon.png) no-repeat center center !important;
}
li.purplecheck{
  background: url(../../img/vrai/purple-check-icon.png) no-repeat center center !important;
}
li.bluecheck{
  background: url(../../img/vrai/blue-check-icon.png) no-repeat center center !important;
}
li.removeicon{
  background: url(../../img/vrai/remove-icon.png) no-repeat center center !important;
}


.lightgray-btn, .dark-btn, .purple-btn, .blue-btn{
  padding: 10px 30px;
  border-radius: 5px;
  margin-top: 40px;
  display: inline-block;
  color: #fff;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: .5px;
}

.tableoption{
  text-align: center;
}

.lightgray-btn{
  background: rgb(255,255,255);
background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(226,226,226,1) 100%);
color: #000;
border: solid 1px #000;
}

a:hover.lightgray-btn{
 color: #000; 
}

.dark-btn{
  background: rgb(107,111,121);
background: linear-gradient(90deg, rgba(107,111,121,1) 0%, rgba(32,32,35,1) 100%);
}

.purple-btn{
  background: rgb(144,8,166);
background: linear-gradient(90deg, rgba(144,8,166,1) 0%, rgba(64,0,75,1) 100%);
}

.blue-btn{
  background: rgb(1,130,226);
background: linear-gradient(90deg, rgba(1,130,226,1) 0%, rgba(0,24,161,1) 100%);
}

.carousel .slide img {
  height: 190px !important;
}

.carousel-root .carousel .control-prev.control-arrow, .carousel-root .carousel .control-next.control-arrow{
  background: url(../../img/vrai/next-arrow.png) no-repeat center center !important;
}

.carousel.carousel-slider .control-arrow {
  width: 70px;
  height: 75px !important;
}
.carousel .control-dots{
  display: none;
}

.carousel .control-dots .dot {
  background: #a4a6cc !important;
}

#Top_bar .top_bar_right {
  padding: 0 10px 0 2px;
}

#Footer .footer_copy .one-third, #Footer .footer_copy .two-third {
  margin-bottom: 20px;
  padding-top: 30px;
  min-height: 33px;
  text-align: left;
}

#Footer .footer_copy .two-third{
  text-align: right;
}

#Footer .fa, .fas{
  font-weight: 100;
  background: #5f6081;
  border-radius: 25px;
  padding: 8px 5px;
  margin-right: 8px;
}

button.close{  
  padding: 3px 10px;
  font-weight: 100;
  border-color: #fff;
  color: #fff !important;
}

button.close:hover{  
  background: #333;
}

.button-stroke button{
  border-color: transparent;
  color: #fff !important;
}

.button-stroke button:hover{
background: none;
  color: #ccc !important;
}

.about-image p.date{  
 /*  margin: 0 auto;
  border: solid 2px #6eeaff;
  padding: 10px 20px;
  color: #6eeaff;
  font-weight: 300; */
}

.section-title h2.contactustitle {
  color: #fff;
  font-size: 60px; 
  text-transform: uppercase;
}

.cont_desc{
  /* margin-top: 80px; */
}

h6 {
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  letter-spacing: 0px;
}

.cont_desc p {
  font-size: 20px;
  font-weight: 300;
  font-family: 'Barlow Condensed';
  line-height: 34px;
  margin-bottom: 50px;
}

.contact-store-location-box img{
  margin: 0 auto;
}

.single-store-location {
  padding-left: 0;
  padding-top: 30px;
}

.single-store-location p a {
  color: rgb(164, 166, 204);
  letter-spacing: 0px;
  font-size: 17px;
  font-weight: 400;
  font-family: 'Barlow Condensed';
  margin-top: 20px;
}

.single-store-location p a.email {
  color: #6eeaff;
}

.btn_sec button.fcf-btn.submit_btn { 
  color: #6eeaff !important;
  border: solid 2px #6eeaff;
  text-transform: uppercase;
  font-family: 'Barlow Condensed';
  background: none;
}

.btn_sec button.fcf-btn.submit_btn:hover { 
  background: #6eeaff;
  color: #fff !important;
}

 input[type="email"], input[type="text"], textarea {
  font-size: 17px;
  font-family: 'Barlow Condensed';
}

.formfield {
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.formmessage {
  width: 100%;
  padding-left: 11px;
}

input.fcf-form-control{
width: 49%;
border-radius: 5px;
margin-left: 11px;
}

input.fcf-form-control.email{
  width: 100%;
  border-radius: 5px;
}

textarea.fcf-form-control{
  border-radius: 5px;
}


.single-store-location p {
  color: rgb(164, 166, 204);  
  font-size: 17px;
  font-family: 'Barlow Condensed';
  line-height: 26px;
}

.contact-store-location-box .image_frame{
  margin-top: -110px;
}

h2.ft-thirty {
  color: #fff;
  font-family: 'Barlow Condensed'; 
}

.ft-thirty p {
  font-family: 'Barlow Condensed';
  font-size: 17px;
}

.pvcpol_pg .fancy-divider svg{
  background: none !important;
}

.list-group-item {
  background-color: #3632506e;
  margin-bottom: 5px;
}

.sitemap-web a {
  color: #fff !important;
}

.list-group-item-action:focus, .list-group-item-action:hover {
  background-color: transparent;
}

.text-muted {
  color: #5f6081 !important;
}
 
.lightbg{
  background: #fff;
}


.lightbg .plan-section{
  display: none;
}

.lightbg .price-area{ 
  padding: /* 250px 0 100px */ 160px 0 100px;
  margin-top: /* 60px */ 0px;
}


#aboutus_pg{
  height: auto;
}



.column_button .button {
  border-color: #6eeaff;
}

.column_button .button:hover {
  color: #fff;
}

.button.button_size_2:before{
  background: none;
}

.button.button_size_2:after{
background: #6eeaff;
}

h2.title_clr{
  color: #0d0f39;
}

.section-title h2.whiteclr{
  color: #fff;
}



/* Style Css */

#Top_bar .top_bar_left{
  /* width: 1684.86px; */
}

rs-module-wrap{
  visibility: visible;
  height: 700px;
}


.itsolsec{
  padding-Top: 70px;
  padding-Bottom: 70px;
  background-Color: #ffffff;
}

.itsolsecawd{
  padding-Top: 0px;
  padding-Bottom: 40px;
  /* background-Color: #ffffff; */
}

.mcb-wrap-krunpr0ng, .mcb-wrap-3g41sb4kg, .mcb-wrap-qp8fg7olf {
  padding: 0 1%;
}

.mcb-item-l3750r6u7 .column_attr, .mcb-item-cjchnhkin .column_attr, .mcb-item-7qi7hzxri .column_attr{
  background-Color: #f5f5fc;
  padding: 40px 15% 25px;
  border-Radius: 10px;
}

.mcb-item-l3750r6u7 .image_frame, .mcb-item-cjchnhkin .image_frame, .mcb-item-7qi7hzxri .image_frame{
  margin-Top: -110px;
}

.pt110{
  padding-Top: 110px;
}

.hr_spc{
  margin: 0 auto 5px;
}

.column_divider .hr_spc2{
margin: 0 auto 70px;
}

.hr_spc3{
  margin: 0 auto 30px;
}

.counter_num{
  padding-Top: 110px; 
  padding-Bottom: 70px;
}

.counter_num img{
  width: 80px;
}

.hm_about_sec{
  padding: 0px 1%; 
  height: 644px;
}

.plan-section{
  padding-Top: 70px ;
  /* padding-Bottom: 70px ; */
  background-Color: #ffffff;
}

.plantitle{
  color: #0d0f39;
}

.carousel-root .carousel .slider {
  width: 33.33%;
  margin: 0 0 70px;
}

.award_carousel .carousel-root .carousel .slider{
  width: 13%;
}

.carousel-root {
  width: 100% !important;
}

.myCarousel p{
  font-size: 20px;
  font-family: 'Barlow Condensed', sans-serif;
  color: #a4a6cc;
}

._ss_md_review_start_div {
  color: #a4a6cc;
  padding: 20px 0;
}

body.mobile-side-slide {
  background: none;
}

/* Style Css */



.nav-tabs .nav-link.active{
  color: #fff !important;
  background-color: #801995;
  border-color: transparent;
  border-radius: 5px; 
  border: none;
  overflow: inherit;
}

.nav-tabs .nav-link.active::after {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #801995;
  bottom: -8px;
  content: "";
  left: 50%;
  margin-left: -4px;
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.nav-tabs .nav-link {
  border: 1px solid transparent;  
  margin: 50px 10px 40px;
  border: solid 1px #801995;
  color: #811a96 !important;
  border-radius: 5px;
  font-size: 30px;
  font-family: 'Teko';
  letter-spacing: 1px;
  font-weight: 300;
  padding: 10px 40px;
}

.nav-tabs {
  border-bottom: none;
  justify-content: center;
  margin-top: 60px;
}

.pricetabs .nav-tabs {
  margin-top: 0;
  background: #ffffff;
  padding-bottom: 170px;
}

.price-item.activeplan .lightgray-btn{
  box-shadow: 0 5px 15px rgb(218 221 223);
}

.price-item.activeplan .dark-btn{
  box-shadow: 0 5px 15px rgb(169 176 193);
}

.price-item.activeplan .purple-btn{
  box-shadow: 0 5px 15px rgb(232 160 243);
}

.price-item.activeplan .blue-btn{
  box-shadow: 0 5px 15px rgb(115 192 255);
}

.regular_price{
  font-size: 32px;
  font-weight: 100;
  letter-spacing: 1px;
  margin-right: 5px;
}


.regular_price.purpleclr, .regular_price.blueclr, .regular_price.darkclr{
  /* opacity: .4; */
  position: relative;
  width: auto;
}

.regular_price.purpleclr{
  color: /* #ba00b9; */ #d70606;
}

.regular_price.blueclr{
  color: /* #014dc1; */  #d70606;
}

.regular_price.darkclr{
  color: /* #0d0f39 */ #d70606;
}


.price_cut{
 /*  text-decoration: line-through;
  text-decoration-thickness: from-font; */
}

.column.hmpd{
  margin: 0;
  /* padding: 0; */
}


.regular_price.darkclr::after, .regular_price.purpleclr::after, .regular_price.blueclr::after {
  background: #d70606;
  content: "";
  height: 2px;
  left: 55%;
  position: absolute;
  top: 40%;
  -webkit-transform: translateY(-50%) translateX(-50%) rotate(-9deg);
  transform: translateY(-50%) translateX(-50%) rotate(-9deg);
  width: 100%;
}

.frspc{
    margin-top: 70px;  
}

.table_wrap.yrly {
   margin-top: 355px;
}

.wdt{
  width: 44%;
}

.flwr{
  flex-flow: row wrap;
}

.semititle.wdt.mntpr{
  font-size: 56px;
    width: 100%;
}

.small-text {
  font-family: sans-serif;
  font-size: 14px;
  color: #000;
  padding: 5px;
  margin-bottom: 30px;
}

.semititle.wdt{
  margin-bottom: 15px;
  font-size: 36px;
}

.semititle.wdt small{
       opacity: 1;
}

.section_nwsize{
  max-width: 1000px; 
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.section_nwsize .one-fourth.mcb-wrap {
  width: 12%;
}

.section_nwsize:after {
  content: none;
}
/* .section_nwsize .one-fourth .mcb-column {
  margin-bottom: 0;
} */

.section_nwsize .one-fourth .mcb-column .image_wrapper {
  box-shadow: 0px 26px 11px -19px #2a2a2a;
}

.award_carousel{
  width: 1000px; 
}

.award_carousel .carousel .slider-wrapper.axis-horizontal .slider{
  align-items: center;
}

.award_carousel .carousel.carousel-slider .control-arrow{
  top: 145px !important;
display: none;
}

.award_carousel .carousel .slide img{
  border-radius:0;
  height: auto !important;
  width: 100px !important;
}

.section_nwsize .image_wrapper img{
  width: 95px;
}

.awardsec{
  display: flex;
  align-items: center;  
}


@media (min-width: 1240px) and (max-width: 1600px) {

  #Top_bar .top_bar_left{
    width: 87%;
  } 
  
  .header-transparent #Top_bar .top_bar_right {
    width: 13%;
  }

}


@media (min-width: 1301px) and (max-width: 1600px) {

  .image_frame.whitebg:before{ 
    background: url(../../img/vrai/white-bg.png) no-repeat 0 0 / 100% 180px;
  }

  .image_frame.graybg:before{
    background: url(../../img/vrai/gray-bg.png) no-repeat 0 0 / 95% 180px;
  }
  
  .image_frame.purplebg:before{
    background: url(../../img/vrai/purple-bg.png) no-repeat 0 0 / 95% 180px; 
  }
  
  .image_frame.bluebg:before{
    background: url(../../img/vrai/blue-bg.png) no-repeat 0 0 / 95% 180px;
  }

  .image_frame.whitebg:before, .image_frame.graybg:before, .image_frame.purplebg:before, .image_frame.bluebg:before{
    width: 100%;
  }

  .image_frame.whitebg:before, .image_frame.graybg:before, .image_frame.purplebg:before, .image_frame.bluebg:before{
    left: 0;
    margin: 0 auto;
    right: 0;
    background-position: center top;
  }
 

}

@media (min-width: 1000px) and (max-width: 1400px) {

.semititle.wdt.mntpr {
  font-size: 3.5vw;  
}

.semititle.wdt {
  margin-bottom: 5px;
  width: 100%; 
}

.semititle {
  font-size: 3.5vw;
}

.regular_price {
  margin-right: 0px;
}

.flwr{
  align-items: center !important;
  flex-direction: column;
}



}


@media (min-width: 1000px) and (max-width: 1300px) {

  .image_frame.whitebg:before{ 
    background: url(../../img/vrai/white-bg.png) no-repeat 0 0 / 180px auto;
  }

  .image_frame.graybg:before{
    background: url(../../img/vrai/gray-bg.png) no-repeat 0 0 / 180px auto;
  }
  
  .image_frame.purplebg:before{
    background: url(../../img/vrai/purple-bg.png) no-repeat 0 0 / 180px auto; 
  }
  
  .image_frame.bluebg:before{
    background: url(../../img/vrai/blue-bg.png) no-repeat 0 0 / 180px auto;
  }

  .image_frame.whitebg:before, .image_frame.graybg:before, .image_frame.purplebg:before, .image_frame.bluebg:before{
    width: 100%;
  }

  .image_frame.whitebg:before, .image_frame.graybg:before, .image_frame.purplebg:before, .image_frame.bluebg:before{
    left: 0;
    margin: 0 auto;
    right: 0;
    background-position: center top;
  }

  .pricetabletitle p {
    color: #000;
  }

  .table_format ul li {
    font-size: 17px;
  }

  .pricetabletitle p {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  

}


@media (min-width: 601px) and (max-width: 999px) {

  .image_frame.whitebg:before{ 
    background: url(../../img/vrai/white-bg.png) no-repeat 0 0 / 90% 90%;
  }

  .image_frame.graybg:before{
    background: url(../../img/vrai/gray-bg.png) no-repeat 0 0 / 90% 90%;
  }
  
  .image_frame.purplebg:before{
    background: url(../../img/vrai/purple-bg.png) no-repeat 0 0 / 90% 90%; 
  }
  
  .image_frame.bluebg:before{
    background: url(../../img/vrai/blue-bg.png) no-repeat 0 0 / 90% 90%;
  }

  .image_frame.whitebg:before, .image_frame.graybg:before, .image_frame.purplebg:before, .image_frame.bluebg:before{
    width: 100%;
  }

  .image_frame.whitebg:before, .image_frame.graybg:before, .image_frame.purplebg:before, .image_frame.bluebg:before{
    left: 0;
    margin: 0 auto;
    right: 0;
    background-position: center top;
  }

  .table_format {
    margin-bottom: 150px;
}

}










@media screen and (min-width: 1000px) {
  .moblist{
    display: none;
  }
  
}

@media screen and (min-width: 1240px) {
  .mobmenu{
    display: none;
  }
  
}


@media screen and (max-width: 1239px) {
  .mobmenu{  
    position: absolute;
    right: 15px;
    top: 25px;
  }

  .offcanvas-end {
    width: 250px;
    background-color: #191919;
  }
  
  .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {  
    background-color: transparent;
    border-color: transparent;
  }
  
  .button-stroke .button_theme:hover, .button-stroke button:hover, .button-stroke input[type="submit"]:hover, .button-stroke input[type="reset"]:hover, .button-stroke input[type="button"]:hover {
    background-color: transparent;
    border: none;
  }
  
  .btn-primary.focus, .btn-primary:focus { 
    background-color: transparent;
    border-color: transparent;
    box-shadow: 0 0 0 0rem rgb(38 143 255 / 50%);
  }
  
  
  .button-stroke button.btn-close{
    background-color: #ffffff;
  }

  .navbar-toggler {
   border: none !important;
}

#menu-main-menu li a {
  display: block;
  padding: 11px 5px 10px 20px;
  margin-right: 50px;
  color: #e1e5fc;
  line-height: 19px;
}

.is-sticky .navbar-toggler span{
  background-color:#000;
}

#Top_bar .top_bar_left {
  width: 80% !important;
}

.header-transparent #Top_bar .top_bar_right {
  width: 20%;
  padding: 0;
}
  
}

@media only screen and (max-width: 999px) {

  .table_wrap{
    display: none;
  }

  .list{
    display: none;
  }

  .section_nwsize .one-fourth .mcb-column .image_wrapper {
    width: fit-content;
    margin: 0 auto;
  }

}


@media only screen and (max-width: 767px) {
  
.navbar-toggler span{
  background-color:#000;
}

.button-stroke button{
  border: none;
}

#Top_bar .top_bar_left {
  width: 100% !important;
}





.hdr_section h1{
  font-size: 56px;
}

  .hdr_content {
      text-align: center;
      padding: 0 20px;
  }

 

  .image_frame.whitebg:before, .image_frame.graybg:before, .image_frame.purplebg:before, .image_frame.bluebg:before {      
    right: -3px;
  }

  .table_format {
    margin-bottom: 160px;
  }

  .table_format.bluebgitem {
    margin-bottom: 0px;
  }

  .image_frame.whitebg, .image_frame.graybg, .image_frame.purplebg, .image_frame.bluebg{
    margin-top: -160px !important;
  }

  .image_frame.whitebg:before {
    right: 7px;
  }

  .hdr_section {     
    padding-top: 70px;
  }

  rs-module-wrap {
    height: 400px;
  }

  .txt_center_resp{
    text-align: center;
  }

  .carousel-root .carousel .slider{
    width: 100%;
  }

  

  .table_format ul li {
    text-align: center;
    padding: 0 15px;
}

.table_format ul li.disable{
  color: #a4a6cc;
  text-decoration: line-through;
  text-decoration-thickness: from-font;
}

 
.pricetabletitle p {
  width: 270px;
  margin: 0 auto;
}

.image_frame.whitebg:before, .image_frame.graybg:before, .image_frame.purplebg:before, .image_frame.bluebg:before{
  left: 0;
  margin: 0 auto;
  right: 0;
  background-position: center top;
}

#Top_bar a.responsive-menu-toggle {
  display: block;
}

.col-lg-3.col-md-6.col-sm-12.price-item.activeplan {
  flex: 0 0 50%;
  max-width: 50%;
}

.awardsec {
  flex-direction: column;
}

.section_nwsize{
  flex-direction: column;
}

.section_nwsize.section_wrapper .column {
  margin: 0 0 40px; 
}

 

}


@media only screen and (max-width: 600px) {
  .col-lg-3.col-md-6.col-sm-12.price-item.activeplan {
    flex: 0 0 100%;
    max-width: 100%;
  }

   
}

@media (min-width: 768px) and (max-width: 992px){
  
  .section_nwsize {
    max-width: 700px;
    flex-wrap: wrap;
  }
  
  .section_nwsize .one-fourth.mcb-wrap {
    width: 30%;
  }

}


@media (min-width: 768px) and (max-width: 1199px){
  .award_carousel .carousel-root .carousel .slider{
    width: 33.33%;
  }
}


@media only screen and (max-width: 991px) {

  #Top_bar{
    background: #fff;
    top: 0px !important;
  }

  .navbar-toggler span {
    background-color: #000;
  }

  .navbar-b .black-logo {
    display: block;
  }

  img.white-logo{
    display: none;
  }

  .container.top-menu::after{
    content: none;
  }

  .navbar-b.navbar-trans .nav-link{
    color: #000;
  }

  .nav-tabs .nav-link{
    padding: 10px 25px;
  }

  .hdr_content p{
    text-align: center;
  }

  .semititle.wdt:nth-child(2) {
    width: auto;
    margin-left: 10px;
  }

  .award_carousel {
    width: auto;
}

  .award_carousel .carousel-root .carousel .slider{
    width: 100%;
  }

 
  
  .section_nwsize .image_wrapper img {
    width: 150px;
}

}


 
